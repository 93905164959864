import React from 'react';
import { Helmet } from 'react-helmet';

import { useHomeData } from '../hooks/useHomeData';
import { HomeHeader } from '../components/Home/HomeHeader/HomeHeader';
import { HomeWorldCups } from '../components/Home/HomeWorldCups/HomeWorldCups';

import '../styles/reset.css';
import '../styles/styles.css';

export default function IndexPage() {
  const { yearsAsChampion, worldCups } = useHomeData();

  return (
    <>
      <Helmet>
        <title>Brasil em Copas</title>
        <meta
          name="description"
          content="Veja todos os dados da Seleção Brasileira de futebol, a seleção penta campeã da Copa do Mundo da Fifa."
        />
      </Helmet>
      <HomeHeader yearsAsChampion={yearsAsChampion} />
      <HomeWorldCups worldCups={worldCups} />
    </>
  );
}
