import React from 'react';

import { WorldCupPlayer } from '../../../types/home';

import styles from './HomeWorldCupsPlayers.module.css';

export function HomeWorldCupsPlayers({
  players,
}: {
  players: WorldCupPlayer[];
}) {
  const startingEleven = players
    .sort((a, b) => (a.order < b.order ? 1 : 0))
    .slice(0, 11);

  return (
    <ol className={styles.players}>
      {startingEleven.map(player => {
        return (
          <li className={styles.player} key={player.order}>
            <span className={styles.number}>{player.number}</span>
            {player.name}
            {player.isCaptain && <span className={styles.captain}>C</span>}
          </li>
        );
      })}
    </ol>
  );
}
