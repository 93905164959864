import React from 'react';

export function IconStart({ className }: { className: string }) {
  return (
    <svg
      className={className}
      height="10"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
    >
      <path
        d="M5.5 8.25l-3.233 1.7.618-3.6L.269 3.8l3.615-.525L5.5 0l1.616 3.275 3.615.525-2.616 2.55.618 3.6z"
        fill="#FFCB02"
        fillRule="evenodd"
      />
    </svg>
  );
}
