import React from 'react';

import styles from './HomeHeader.module.css';

export function HomeHeader({ yearsAsChampion }: { yearsAsChampion: number[] }) {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>Brasil em Copas</h1>
      <div className={styles.subtitle}>
        <p className={styles.subtitleText}>Pentacampeão</p>
        <p className={styles.subtitleYears}>
          {yearsAsChampion.map(year => (
            <span className={styles.subtitleYear} key={year}>
              {year}
            </span>
          ))}
        </p>
      </div>
    </header>
  );
}
