import { useStaticQuery, graphql } from 'gatsby';
import { WorldCup } from '../types/home';

export function useHomeData() {
  const data = useStaticQuery(graphql`
    query worldCups {
      allWorldCupJson {
        nodes {
          country
          year
          standing
          players {
            order
            name
            isCaptain
            number
            abbr
          }
        }
      }
    }
  `);

  const worldCups: WorldCup[] = data.allWorldCupJson.nodes;
  const yearsAsChampion = worldCups
    .filter(worldCup => worldCup.standing === 1)
    .map(worldCup => worldCup.year);

  return {
    yearsAsChampion,
    worldCups,
  };
}
