import React from 'react';

import { HomeWorldCupsPlayers } from '../HomeWorldCupsPlayers/HomeWorldCupsPlayers';
import { IconStart } from '../../Icons/IconStart';
import { WorldCup } from '../../../types/home';

import styles from './HomeWorldCups.module.css';

export function HomeWorldCups({ worldCups }: { worldCups: WorldCup[] }) {
  return (
    <div className={styles.wrapper}>
      {worldCups.map(worldCup => {
        return (
          <div className={styles.worldCup} key={worldCup.year}>
            <p className={styles.year}>
              {worldCup.year}
              <IconStart className={styles.star} />
            </p>

            <HomeWorldCupsPlayers players={worldCup.players} />
          </div>
        );
      })}
    </div>
  );
}
